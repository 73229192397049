// React
import React from 'react';
import {useState, useCallback, useEffect, useRef} from 'react';

// MUI
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function EcProperties(props) {
    const [value, setValue] = useState(props.node.fb.pollPeriod);
    
    function handleChange(e) {
        props.node.fb.pollPeriod = e.target.value;
        setValue(e.target.value);
        props.changeFunc(props.node);
    }
    return (
        <Box sx={{display:'flex', flexDirection:'column', padding:2}}>
            {/* {props.node.fb.nodeType} */}
            <TextField label="Read frequency" value={props.node.fb.pollPeriod} helperText="seconds" type="number" onChange={handleChange}/>
        </Box>
    )
};

export {EcProperties};
// time in seconds
export const EcPropertiesExtension = {pollPeriod:30};

