// React
import React from 'react';
import {useState, useCallback, useEffect, useRef} from 'react';

// MUI
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function LtProperties(props) {
    const [value, setValue] = useState(props.node.fb.lessThan);
    
    function handleChange(e) {
        props.node.fb.lessThan = e.target.value;
        setValue(e.target.value);
        props.changeFunc(props.node);
    }
    return (
        <Box sx={{display:'flex', flexDirection:'column', padding:2}}>
            {/* {props.node.fb.nodeType} */}
            <TextField label="Less than" value={props.node.fb.lessThan} type="number" onChange={handleChange}/>
        </Box>
    )
}

export {LtProperties};
export const LtPropertiesExtension = {lessThan:30};

