// React
import React from 'react';
import {useState, useCallback, useEffect, useRef} from 'react';
import update from 'immutability-helper';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// others
import { format, parseISO } from 'date-fns';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useInterval from 'use-interval';

import './App.css';
import logo from './logo.svg';

export default function Dashboard(props) {
    const [base, setBase] = useState(10);
    const [lastTickMs, setLastTickMs] = useState(0);
    const [loginToken, setLoginToken] = useState("");
    const [devices, setDevices] = useState({
    });

    // setup polling ticks
    {/* useInterval( */}
    {/*     () => tick1s(), */}
    {/*     1000 */}
    {/* ); */}
    useInterval(
        () => tick5s(),
        5000
    );
        
    useEffect(() => {
        // HACK: login
        const loginAccountObj = {username:"hupseng", password:"hupseng"};
        const loginIdObj = {userId:2}
        const loginRequestOptions = {
            method: 'POST',
            headers: {},
            body: JSON.stringify(loginAccountObj)
        };
        fetch("https://api.farmblocks.io/account/login", loginRequestOptions)
            .then(response => {
                return response.json();
            })
            .then(responseObj => {
                //console.log("body = " + JSON.stringify(responseObj));
                setLoginToken(responseObj.token);
                console.log("responseObj.token = " + responseObj.token);
                const devicesRequestOptions = {
                    method: 'POST',
                    headers: {'Authorization': 'Bearer ' + responseObj.token},
                    body: JSON.stringify(loginIdObj)
                };
                return fetch("https://api.farmblocks.io/devices/userid", devicesRequestOptions);
            })
            .then(response => {
                return response.json();
            })
            .then(responseObj => {
                // sample response: {"devices":[{"id":2,"controllerId":4,"deviceType":2,"name":"some EC probe","dateCreated":"2022-05-14T08:50:08.097596Z","dateUpdated":"2022-05-14T08:50:08.097596Z","isDeleted":false,"ownerId":1,"controllerType":2}, ...]}
                console.log("body = " + JSON.stringify(responseObj));
                var myDevices = {};
                for (const device of responseObj.devices) {
                    var newDevice = {
                        deviceId: device.id,
                        deviceType: device.deviceType,
                        name: device.name,
                        chartData: []
                    };
                    if ((device.deviceType >= 1 && device.deviceType <= 5)
                        || device.deviceType == 8) {
                        myDevices[""+device.id] = newDevice;
                    }
                }
                setDevices(myDevices);
            })
        // TODO: doesn't work, because state variables are captured in useEffect. need to find another way to make it update immediately, else it seems the site is lagging
            // .then(() => {
            //     console.log("fetching sensor data");
            //     tick10s()
            // });
    }, []);
    
    function tick1s() {
        var newBase = base;
        var now = new Date();
        var nowUnixMs = now.getTime();
        // update base every 10 seconds, randomly add -1 to 1
        if (Math.floor(nowUnixMs/10) != Math.floor(lastTickMs/10)) {
            newBase = newBase - 1 + (Math.random() * 2);
        }

        // add pH data point, base + random from -1 to 1
        var newDatapoint1 = newBase - 1 + (Math.random() * 2);
        var chartData1 = [...devices["1"].chartData, {date: format(now, 'HH:mm:ss'), value: newDatapoint1}];
        if (chartData1.length > 20)
            chartData1.shift();

        // add EC data point, base + random from -1 to 1
        var newDatapoint2 = newBase - 1 + (Math.random() * 2);
        var chartData2 = [...devices["3"].chartData, {date: format(now, 'HH:mm:ss'), value: newDatapoint2}];
        if (chartData2.length > 20)
            chartData2.shift();

        // update newDevices to include new datapoints
        var newDevices = update(devices, {
            1: {chartData: {$set:chartData1}},
            3: {chartData: {$set:chartData2}}
        });

        setBase(newBase);
        setLastTickMs(nowUnixMs);
        setDevices(newDevices);
    }

    function tick5s() {
        // update each device with fetched data
        
        // fetch data from server
        var deviceIds = [];
        for (const key in devices) {
            deviceIds.push(devices[key].deviceId);
        }
        const sensorRequestOptions = {
            method: 'POST',
            headers: {'Authorization': 'Bearer ' + loginToken},
            body: JSON.stringify({deviceIds:deviceIds})
        };
        fetch("https://api.farmblocks.io/devices/readings", sensorRequestOptions)
            .then(response => {
                return response.json();
            })
            .then(responseObj => {
                // replace devices' chartData with fetched data from server
                // sample response: {"devices":[{"deviceId":5, "readings":[{"date":"2023-01-24T07:28:14Z","id":2,"readingType":2,"value":6.4}]}]}
                {/* console.log("body = " + JSON.stringify(responseObj)); */}
                var replacementDevices = structuredClone(devices);
                for (const device of responseObj.devices) {
                    var newChartData = [];
                    for (const reading of device.readings) {
                        newChartData.push({
                            date: format(parseISO(reading.date), 'HH:mm:ss'),
                            value: reading.value,
                        });
                    }
                    // recharts crashes if any chart has only 1 data point
                    if (newChartData.length == 1) newChartData = [];
                    var newChartDataReversed = newChartData.reverse();
                    replacementDevices[device.deviceId].chartData = newChartDataReversed;
                }
                setDevices(replacementDevices);
            });
    }
    
    function renderChartTitle() {
        return (
            <Box sx={{position:'relative', height:'40px', lineHeight:'40px', alignContent:'center', borderBottom:'1px solid', borderTop:'1px solid', borderColor:'grey.300'}}>Title 1</Box>
        )
    }
    
    function renderCharts() {
        var tempArray = [];
        Object.keys(devices).forEach(function(key, index) {
            var device = devices[key];
            tempArray.push(
                <Box key={index+'title'} sx={{position:'relative', height:'40px', lineHeight:'40px', alignContent:'center', borderBottom:'1px solid', borderTop:'1px solid', borderColor:'grey.300'}}>{device.name}</Box>
            );
            tempArray.push(
                <ResponsiveContainer key={index+'chart'} width='100%' height={300}>
                    <LineChart
                        width={500}
                        height={300}
                        data={device.chartData}
                        margin={{top: 5, right: 50, left: 20, bottom: 5}}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey="value" stroke="#8884d8" isAnimationActive={false}/>
                    </LineChart>
                </ResponsiveContainer>
            );
        });
        return tempArray;
    }
    
    return (
            <Box className="Dashboard" sx={{overflow:'overlay', display:'flex', flexDirection:'column', width:'100%', height:'100%', borderTop:'1px solid', borderColor:'grey.300' }}>
                
                {renderCharts()}
                
            </Box>
    );
}

