// React
import React from 'react';
import {useState, useCallback, useEffect, useRef} from 'react';
import update from 'immutability-helper';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';

// others
import { useDrop } from 'react-dnd';
import ReactFlow, {useNodesState, useEdgesState, addEdge} from 'react-flow-renderer';

import './App.css';
import logo from './logo.svg';
import CustomDragLayer from './CustomDragLayer.jsx';
import FlowPaletteBox from './FlowPaletteBox.jsx';
import {PhProperties, PhPropertiesExtension} from './properties/PhProperties.jsx';
import {EcProperties, EcPropertiesExtension} from './properties/EcProperties.jsx';
import {GtProperties, GtPropertiesExtension} from './properties/GtProperties.jsx';
import {LtProperties, LtPropertiesExtension} from './properties/LtProperties.jsx';
import {PumpProperties, PumpPropertiesExtension} from './properties/PumpProperties.jsx';
import {TimerProperties, TimerPropertiesExtension} from './properties/TimerProperties.jsx';
import {DelayProperties, DelayPropertiesExtension} from './properties/DelayProperties.jsx';
import { DragItemTypes, FlowNodeTypes } from './DragItemTypes.jsx';

export const Flow = (props) => {
    const [leftPaletteBoxes, setLeftPaletteBoxes] = useState([
        {id:'aa', nodeType:FlowNodeTypes.GT_NODE, top:1, left:20, width:30, height:30, title:">", extension:GtPropertiesExtension},
        {id:'ab', nodeType:FlowNodeTypes.LT_NODE, top:170, left:20, width:30, height:30, title:"<", extension:LtPropertiesExtension},
        {id:'ac', nodeType:FlowNodeTypes.TIMER_NODE, top:170, left:20, width:70, height:30, title:"timer", extension:TimerPropertiesExtension},
        {id:'ad', nodeType:FlowNodeTypes.DELAY_NODE, top:170, left:20, width:70, height:30, title:"delay", extension:DelayPropertiesExtension},
    ]);
    const [leftDeviceBoxes, setLeftDeviceBoxes] = useState([]);
    const leftDeviceBoxesRef = useRef({});
    leftDeviceBoxesRef.current = leftDeviceBoxes;
    const [canvasBoxes, setCanvasBoxes, onCanvasBoxesChange] = useNodesState([
//        {id:'n', type:'default', position:{x:150, y:100}, data:{label:'EC sensor A'}, fb:{nodeType:FlowNodeTypes.EC_NODE, top: 380, left: 150, width:150, height:30, title: "EC sensor"}},
//        {id:'m', type:'default', position:{x:450, y:100}, data:{label:'pH sensor A'}, fb:{nodeType:FlowNodeTypes.PH_NODE, top: 300, left: 450, width:150, height:30, title: "pH sensor"}},
//        {id:'o', type:'default', position:{x:150, y:280}, data:{label:'>'}, fb: {nodeType:FlowNodeTypes.GT_NODE, top: 200, left: 150, width:30, height:30, title: ">"}},
//        {id:'p', type:'default', position:{x:450, y:380}, data:{label:'pump A'}, fb: {nodeType:FlowNodeTypes.PUMP_NODE, top: 100, left: 450, width:150, height:30, title: "Pump A"}},
    ]);
    const [canvasConnectors, setCanvasConnectors, onCanvasConnectorsChange] = useEdgesState([
//        {id:'x', source:'o', target:'p'},
//        {id:'y', source:'m', target:'o'},
//        {id:'z', source:'n', target:'p'},
    ]);
    const [selectedItem, setSelectedItem] = useState(null);
    const canvasRef = useRef();
    const paletteRef = useRef();
    const devicesRef = useRef();
    
    const tempRef = useRef({}); tempRef.current = {canvasBoxes:canvasBoxes, canvasConnectors:canvasConnectors};

    let intKey = 0;
    // generates next n unique keys not found in array
    function generateNextUniqueKey(arr, n=1) {
        let retval = [];
        for (let i = 0; i < n; i++) {
            let found = true;
            while (found) {
                found = false;
                for (let obj of arr) {
                    if (obj.id === intKey.toString()) {
                        found = true;
                        break;
                    }
                }
                for (let obj of retval) {
                    if (found) break;
                    if (obj.id === intKey.toString()) {
                        found = true;
                        break;
                    }
                }
                if (!found) retval.push(intKey);
                intKey++;
            }
        }
        if (retval.length == 1)
            return retval[0];
        return retval;
    }
    const copyPaletteBox = useCallback(
        (id, nodeType, x, y, width, height, title, extension) => {
            let nextKey = generateNextUniqueKey(canvasBoxes, 1);
            //console.log("creating box id " + nextKey + " location ("+x+","+y+")");
            const newNode = {id:nextKey.toString(), type:'default', position:{x, y}, data:{label:title}, fb:{...extension, nodeType}};
            const newMidBoxes = canvasBoxes.slice(0).push(newNode);
            setCanvasBoxes((nodes) => nodes.concat(newNode));
        },
        [canvasBoxes]
    );
    const [, canvasDropRef] = useDrop(
        () => ({
            accept: [DragItemTypes.PALETTE_BOX],
            drop(item, monitor) {
                console.log("drop detected, type " + item.dragItemType);
                const delta = monitor.getDifferenceFromInitialOffset();
                let relativeLeft = Math.round(item.left + delta.x);
                let relativeTop = Math.round(item.top + delta.y);
                if (item.dragItemType === DragItemTypes.PALETTE_BOX) {
                    // translate to canvas area coordinates
                    const paletteOffset = paletteRef.current.getBoundingClientRect();
                    const canvasOffset = canvasRef.current.getBoundingClientRect();
                    // console.log("relativeLeft " + relativeLeft + ", "+ relativeTop);
                    // console.log("paletteOffset = " + JSON.stringify(paletteOffset));
                    // console.log("canvasOffset = " + JSON.stringify(canvasOffset));
                    let left = Math.round(relativeLeft + paletteOffset.x - canvasOffset.x);
                    let top = Math.round(relativeTop + paletteOffset.y - canvasOffset.y);
                    copyPaletteBox(item.id, item.nodeType, left, top, item.width, item.height, item.title, item.extension);
                }
                return undefined;
            }
        }),
        [canvasBoxes]
    );
    const onConnect = useCallback(
        (newEdge) => {
            setCanvasConnectors((edges) => addEdge(newEdge, edges));
        },
        [canvasConnectors]
    );
    
    // clicks
    const handleEmptyClick = useCallback(
        (e) => {
            console.log(`clicked nothing`);
            setSelectedItem(null);
        },
        [selectedItem]
    );
    const handleClick = useCallback(
        (e, obj) => {
            console.log(`clicked, obj = ${JSON.stringify(obj)}`);
            setSelectedItem(obj);
            e.stopPropagation();
        },
        [selectedItem]
    );
    function autodoserClicked() {
        console.log("autodoser template clicked");
        //console.log(`canvasBoxes = ${JSON.stringify(canvasBoxes)}`);
        let newIds = generateNextUniqueKey(canvasBoxes, 12).map((intId) => intId.toString());
        let newCanvasBoxes = [];
        newCanvasBoxes.push({id:newIds[0], type:'default', position:{x:80, y:0}, data:{label:'EC sensor A'}, fb:{nodeType:FlowNodeTypes.EC_NODE, pollPeriod:10}});
        newCanvasBoxes.push({id:newIds[1], type:'default', position:{x:370, y:0}, data:{label:'pH sensor A'}, fb:{nodeType:FlowNodeTypes.PH_NODE, pollPeriod:20}});
        newCanvasBoxes.push({id:newIds[2], type:'default', position:{x:370, y:80}, data:{label:'>'}, fb:{nodeType:FlowNodeTypes.GT_NODE, greaterThan:3.5}});
        newCanvasBoxes.push({id:newIds[3], type:'default', position:{x:80, y:80}, data:{label:'<'}, fb:{nodeType:FlowNodeTypes.LT_NODE, lessThan:1300}});
        newCanvasBoxes.push({id:newIds[4], type:'default', position:{x:10, y:160}, data:{label:'dosing pump A'}, fb:{nodeType:FlowNodeTypes.PUMP_NODE, runFor:10}});
        newCanvasBoxes.push({id:newIds[5], type:'default', position:{x:180, y:160}, data:{label:'dosing pump B'}, fb:{nodeType:FlowNodeTypes.PUMP_NODE, runFor:2}});
        newCanvasBoxes.push({id:newIds[6], type:'default', position:{x:370, y:160}, data:{label:'dosing pump C'}, fb:{nodeType:FlowNodeTypes.PUMP_NODE, runFor:2}});
        setCanvasBoxes((nodes) => nodes.concat(newCanvasBoxes));
        
        let newCanvasConnectors = [];
        newCanvasConnectors.push({id:newIds[7], type:'default', source:newIds[0], target:newIds[3]});
        newCanvasConnectors.push({id:newIds[8], type:'default', source:newIds[3], target:newIds[4]});
        newCanvasConnectors.push({id:newIds[9], type:'default', source:newIds[3], target:newIds[5]});
        newCanvasConnectors.push({id:newIds[10], type:'default', source:newIds[1], target:newIds[2]});
        newCanvasConnectors.push({id:newIds[11], type:'default', source:newIds[2], target:newIds[6]});
        //console.log(`newIds = ${JSON.stringify(newIds)}`);
        //console.log(`newCanvasBoxes = ${JSON.stringify(newCanvasBoxes)}`);
        //console.log(`newCanvasConnectors = ${JSON.stringify(newCanvasConnectors)}`);
        setCanvasConnectors((edges) => edges.concat(newCanvasConnectors));
    };
    
    // keypresses
    function handleKeyPress(e) {
        console.log(`key pressed = ${e.key}`);
        if (e.key === "r") {
            let leftDeviceBoxes = leftDeviceBoxesRef.current;
            // HACK: add devices to list
            let devicesToAdd = [
                {id:'ba', nodeType:FlowNodeTypes.EC_NODE, top:20, left:20, width:150, height:30, title:"EC sensor A", extension:EcPropertiesExtension},
                {id:'bb', nodeType:FlowNodeTypes.PH_NODE, top:70, left:20, width:150, height:30, title:"pH sensor A", extension:PhPropertiesExtension},
                {id:'bc', nodeType:FlowNodeTypes.PUMP_NODE, top:220, left:20, width:150, height:30, title:"dosing pump A", extension:PumpPropertiesExtension},
                {id:'bd', nodeType:FlowNodeTypes.PUMP_NODE, top:220, left:20, width:150, height:30, title:"dosing pump B", extension:PumpPropertiesExtension},
                {id:'be', nodeType:FlowNodeTypes.PUMP_NODE, top:220, left:20, width:150, height:30, title:"dosing pump C", extension:PumpPropertiesExtension},
                {id:'bf', nodeType:FlowNodeTypes.PUMP_NODE, top:220, left:20, width:150, height:30, title:"water pump D", extension:PumpPropertiesExtension},
                {id:'bg', nodeType:FlowNodeTypes.PUMP_NODE, top:220, left:20, width:150, height:30, title:"water pump E", extension:PumpPropertiesExtension},
            ];
            let newArray = [].concat(leftDeviceBoxes, devicesToAdd);
            setLeftDeviceBoxes(newArray);
            console.log(`new array = ${JSON.stringify(newArray)}`);
        }
        if (e.key === "p") {
            // print debug
            let canvasBoxes = tempRef.current.canvasBoxes;
            let canvasConnectors = tempRef.current.canvasConnectors;
            console.log(`canvasBoxes = ${JSON.stringify(canvasBoxes)}`);
            console.log(`canvasConnectors = ${JSON.stringify(canvasConnectors)}`);
        }
    };
    useEffect(() => {
        document.addEventListener('keyup', handleKeyPress);
        return function cleanup() {
            document.removeEventListener('keyup', handleKeyPress);
        }
    }, []);
    
    const renderSelectedItemProperty = useCallback(
        () => {
            //console.log(`rendering property for ${JSON.stringify(selectedItem)}`);
            if (!selectedItem || !selectedItem.fb || !selectedItem.fb.nodeType) {
                return (null);
            }
            switch (selectedItem.fb.nodeType) {
                case FlowNodeTypes.PH_NODE:
                    return (<PhProperties node={selectedItem} changeFunc={setSelectedItem}/>)
                case FlowNodeTypes.EC_NODE:
                    return (<EcProperties node={selectedItem} changeFunc={setSelectedItem}/>)
                case FlowNodeTypes.GT_NODE:
                    return (<GtProperties node={selectedItem} changeFunc={setSelectedItem}/>)
                case FlowNodeTypes.LT_NODE:
                    return (<LtProperties node={selectedItem} changeFunc={setSelectedItem}/>)
                case FlowNodeTypes.PUMP_NODE:
                    return (<PumpProperties node={selectedItem} changeFunc={setSelectedItem}/>)
                case FlowNodeTypes.TIMER_NODE:
                    return (<TimerProperties node={selectedItem} changeFunc={setSelectedItem}/>)
                case FlowNodeTypes.DELAY_NODE:
                    return (<DelayProperties node={selectedItem} changeFunc={setSelectedItem}/>)
            }
        },
        [selectedItem]
    );
    function calculateLeftBoxesPositions(boxes) {
        let y = 20;
        for (let box of boxes) {
            box.left = 20;
            box.top = y;
            y = y + 50;
        }
    };

    calculateLeftBoxesPositions(leftPaletteBoxes);
    calculateLeftBoxesPositions(leftDeviceBoxes);
    return (
        <Box className="Flow" sx={{overflow:'overlay', display:'flex', flexDirection:'row', width:'100%', height:'100%', borderTop:'1px solid', borderColor:'grey.300' }}>
            {/* left util bar, or palettes */}
            <Box sx={{overflow:'auto', position:'relative', display:'flex', flexDirection:'column', width:'20%', height:'100%', borderRight:'1px solid', borderColor:'grey.300'}}>
                {/* Palette nodes */}
                <Box sx={{position:'static', display:'flex', flexDirection:'column', height:(60+leftPaletteBoxes.length*50).toString() + 'px', alignContent:'center'}}>
                    <Box sx={{position:'static', height:'40px', lineHeight:'40px', alignContent:'center', borderBottom:'1px solid', borderColor:'grey.300'}}>Palette</Box>
                    <Box ref={paletteRef} sx={{position:'relative', flexGrow:'1', alignContent:'center', borderBottom:'1px solid', borderColor:'grey.300'}}>
                        {leftPaletteBoxes.map((obj) => (
                            <FlowPaletteBox key={obj.id} {...obj} />
                        ))}
                    </Box>
                </Box>

                {/* Device nodes */}
                <Box sx={{position:'static', display:'flex', flexDirection:'column', height:(60+leftDeviceBoxes.length*50).toString() + 'px', alignContent:'center'}}>
                    <Box sx={{position:'static', height:'40px', lineHeight:'40px', alignContent:'center', borderBottom:'1px solid', borderColor:'grey.300'}}>Devices</Box>
                    <Box ref={devicesRef} sx={{position:'relative', flexGrow:'1', alignContent:'center', borderBottom:'1px solid', borderColor:'grey.300'}}>
                        {leftDeviceBoxes.map((obj) => (
                            <FlowPaletteBox key={obj.id} {...obj} />
                        ))}
                    </Box>
                </Box>
            </Box>
            
            {/* center area */}
            <Box sx={{flexGrow:'1', display:'flex', flexDirection:'column'}}>
                {/* top bar */}
                <Box sx={{height:'30px', display:'flex', flexDirection:'row', borderBottom:'1px solid', borderColor:'grey.300'}}>
                    <Box sx={{flexGrow:'1', margin:'auto'}}>
                        Create your flowchart below and click Save
                    </Box>
                    <Button sx={{margin:'4px', marginRight:'8px'}} variant="contained" onClick={() => {
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'test-secret': 'asdfasdf'
                            },
                            body: JSON.stringify({boardId:2, deviceId:4})
                        };
                        fetch("https://api.farmblocks.io/devices/test", requestOptions)
                            .then(response => console.log(response.json()));
                    }}>
                        Save
                    </Button>
                </Box>

                {/* main flow area */}
                <Box ref={canvasRef} sx={{position:'relative', flexGrow:'1'}} onClick={(e) => handleEmptyClick(e)}>
                    <Box ref={canvasDropRef} sx={{height:'100%', width:'100%'}}>
                        <ReactFlow
                            nodes={canvasBoxes}
                            edges={canvasConnectors}
                            onNodesChange={onCanvasBoxesChange}
                            onEdgesChange={onCanvasConnectorsChange}
                            onConnect={onConnect}
                            onNodeClick={handleClick}
                            onNodeDragStart={handleClick}
                            onEdgeClick={handleClick}
                            fitView
                        />
                    </Box>
                </Box>
            </Box>
            

            {/* right util bar */}
            <Box sx={{position:'relative', display:'flex', flexDirection:'column', width:'20%', height:'100%', borderLeft:'1px solid', borderColor:'grey.300' }}>
                {/* Properties */}
                <Box sx={{position:'relative', display:'flex', flexDirection:'column', height:'50%', alignContent:'center'}}>
                    <Box sx={{position:'relative', height:'40px', lineHeight:'40px', alignContent:'center', borderBottom:'1px solid', borderColor:'grey.300'}}>Properties</Box>
                    <Box sx={{position:'relative', flexGrow:'1', alignContent:'center', borderBottom:'1px solid', borderColor:'grey.300'}}>
                        {renderSelectedItemProperty()}
                    </Box>
                </Box>
                
                <Box sx={{position:'relative', display:'flex', flexDirection:'column', height:'50%', alignContent:'center'}}>
                    <Box sx={{position:'relative', height:'40px', lineHeight:'40px', alignContent:'center', borderBottom:'1px solid', borderColor:'grey.300'}}>Templates</Box>
                    <Box sx={{position:'relative', flexGrow:'1', alignContent:'center', borderBottom:'1px solid', borderColor:'grey.300'}}>
                        <Stack sx={{padding:2}} spacing={2} direction="column">
                            <Button variant="outlined" onClick={autodoserClicked}>Autodoser</Button>
                            <Button variant="outlined" onClick={autodoserClicked}>Flood-and-ebb controller</Button>
                            <Button variant="outlined" onClick={autodoserClicked}>Sprinkler</Button>
                            <Button variant="outlined" onClick={autodoserClicked}>Tank irrigation</Button>
                        </Stack>
                    </Box>
                </Box>
            </Box>

            <CustomDragLayer/>
            
        </Box>

    )
}

export default Flow;
