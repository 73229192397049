// React
import React from 'react';
import {useState, useCallback, useEffect, useRef} from 'react';

// MUI
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function DelayProperties(props) {
    const [value, setValue] = useState(props.node.fb.delayFor);
    
    function handleChange(e) {
        props.node.fb.delayFor = e.target.value;
        setValue(e.target.value);
        props.changeFunc(props.node);
    }
    return (
        <Box sx={{display:'flex', flexDirection:'column', padding:2}}>
            {/* {props.node.fb.nodeType} */}
            <TextField label="Delay for" value={props.node.fb.delayFor} helperText="seconds" type="number" onChange={handleChange}/>
        </Box>
    )
};

export {DelayProperties};
// time in seconds
export const DelayPropertiesExtension = {delayFor:600};

