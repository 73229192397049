import { useDragLayer } from 'react-dnd'
import { FlowPaletteBox } from './FlowPaletteBox.jsx'
import { FlowDraggableBox } from './FlowDraggableBox.jsx'
import { DragItemTypes } from './DragItemTypes.jsx'
const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
}

function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        }
    }
    let { x, y } = currentOffset
    const transform = `translate(${x}px, ${y}px)`
    return {
        transform,
        WebkitTransform: transform,
    }
}

export const CustomDragLayer = (props) => {
    const { itemType, isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }))
    function renderItem() {
        const clonedItem = {...item};
        clonedItem.left = currentOffset.x;
        clonedItem.top = currentOffset.y;
        switch (itemType) {
            case DragItemTypes.PALETTE_BOX:
                return <FlowPaletteBox {...clonedItem}/>
            case DragItemTypes.FLOW_BOX:
                return <FlowDraggableBox {...clonedItem}/>
            default:
                return null
        }
    }
    if (!isDragging || !initialOffset || !currentOffset) {
        return null
    }
    return (
        <div style={layerStyles}>
            {/* <div style={getItemStyles(initialOffset, currentOffset)}> */}
            <div>
                {renderItem()}
            </div>
        </div>
    )
}

export default CustomDragLayer;
