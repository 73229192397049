import { memo, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { DragItemTypes } from './DragItemTypes.jsx'

function getStyles(left, top, width, height, isDragging) {
    const transform = `translate3d(${left}px, ${top}px, 0)`
    return {
        position: 'absolute',
        top:'0px',  // with no top or left, position 'absolute' doesn't seem to take effect
        width:`${width}px`, 
        height:`${height}px`, 
        border:'1px solid', 
        borderColor:'black',
        transform,
        WebkitTransform: transform,
        // IE fallback: hide the real node using CSS when dragging
        // because IE will ignore our custom "empty image" drag preview.
        opacity: isDragging ? 0.5 : 1,
        // height: isDragging ? 0 : '',
    }
}

export const FlowPaletteBox = memo(function FlowPaletteBox(props) {
    const { id, title, nodeType, left, top, width, height, extension } = props
    const dragItemType = DragItemTypes.PALETTE_BOX
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: dragItemType,
            item: { id, dragItemType, nodeType, left, top, width, height, title, extension },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [id, left, top, width, height, title],
    )
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    return (
        <div
            ref={drag}
            style={getStyles(left, top, width, height, isDragging)}
            role="FlowPaletteBox"
        >
            <div 
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}>
                {title}
            </div>
        </div>
    )
})

export default FlowPaletteBox;
