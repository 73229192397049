// React
import React from 'react';
import {useState, useCallback, useEffect, useRef} from 'react';

// MUI
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function GtProperties(props) {
    const [value, setValue] = useState(props.node.fb.greaterThan);
    
    function handleChange(e) {
        props.node.fb.greaterThan = e.target.value;
        setValue(e.target.value);
        props.changeFunc(props.node);
    }
    return (
        <Box sx={{display:'flex', flexDirection:'column', padding:2}}>
            {/* {props.node.fb.nodeType} */}
            <TextField label="Greater than" value={props.node.fb.greaterThan} type="number" onChange={handleChange}/>
        </Box>
    )
}

export {GtProperties};
export const GtPropertiesExtension = {greaterThan:30};

