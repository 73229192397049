export const DragItemTypes = {
    PALETTE_BOX: 'palbox',
    FLOW_BOX: 'flowbox',
};

export const FlowNodeTypes = {
    PH_NODE:'phnode',
    EC_NODE:'ecnode',
    GT_NODE:'gtnode',
    LT_NODE:'ltnode',
    TIMER_NODE:'timernode',
    DELAY_NODE:'delaynode',
    PUMP_NODE:'pumpnode',
    ARROW:'arrow',
};

