import React from 'react';
import logo from './logo.svg';
import './App.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Flow from './Flow.jsx';
import Dashboard from './Dashboard.jsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const TabsV = Object.freeze({
    TABS_DASHBOARD: ("dashboard"),
    TABS_FLOW: ("flow"),
    TABS_SETTINGS: ("settings"),
});

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: TabsV.TABS_DASHBOARD
        };
    }

    render() { 
        const handleTabChanged = (event, newValue) => {
            this.setState({selectedTab: newValue});
        };
         
        return (
            <DndProvider backend={HTML5Backend}>
                <Box className="App" sx={{overflow:'hidden', width:'100vw', height:'100vh'}}>
                    <TabContext value={this.state.selectedTab}>
                        <TabList onChange={handleTabChanged} aria-label="basic tabs">
                            <Tab label="Dashboard" id="tab_one" value={TabsV.TABS_DASHBOARD}/>
                            <Tab label="Flow" id="tab_two" value={TabsV.TABS_FLOW}/>
                            <Tab label="Settings" id="tab_three" value={TabsV.TABS_SETTINGS}/>
                        </TabList>
                        <TabPanel value={TabsV.TABS_DASHBOARD} sx={{overflow:'hidden', padding: '0', width:'100%', height:'100%'}}>
                            <Dashboard/>
                        </TabPanel>
                        <TabPanel value={TabsV.TABS_FLOW} sx={{overflow:'hidden', padding: '0', width:'100%', height:'100%'}}>
                            <Flow/>
                        </TabPanel>
                        <TabPanel value={TabsV.TABS_SETTINGS} sx={{overflow:'hidden', padding: '0', width:'100%', height:'100%'}}>
                            Settings
                        </TabPanel>
                    </TabContext>
                </Box>
            </DndProvider>
        );}
        
/*
        return (
            <Box className="App" sx={{display:'flex', flexDirection:'column', height:'100vh'}}>
                <TabContext value={this.state.selectedTab}>
                    <TabList onChange={handleTabChanged} aria-label="basic tabs">
                        <Tab label="Dashboard" id="tab_one" value={TabsV.TABS_DASHBOARD}/>
                        <Tab label="Flow" id="tab_two" value={TabsV.TABS_FLOW}/>
                        <Tab label="Settings" id="tab_three" value={TabsV.TABS_SETTINGS}/>
                    </TabList>
                    <TabPanel value={TabsV.TABS_DASHBOARD} sx={{ padding: '0', flexGrow:'1' }}>
                        <Dashboard/>
                    </TabPanel>
                    <TabPanel value={TabsV.TABS_FLOW} sx={{ padding: '0', flexGrow:'1' }}>
                        <Flow/>
                    </TabPanel>
                    <TabPanel value={TabsV.TABS_SETTINGS} sx={{ padding: '0', flexGrow:'1' }}>
                        Settings
                    </TabPanel>
                </TabContext>
            </Box>
        );}
*/
}

export default App;
